import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { formatDate } from "../utils/data"

const BlogPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Blog" />
      <div className="max-w-5xl mx-auto w-full">
        <div className="my-8 text-center">
          <h1 className="my-8">Blog</h1>
        </div>
      </div>
      <div className="flex flex-wrap max-w-6xl mx-auto w-full mb-20">
        {data.allPrismicBlog.edges.map(({ node }, i) => {
          const { title, featured_image, publish_date } = node.data
          return (
            <Link
              to={`/blog/${node.uid}/`}
              className="block w-full sm:w-1/2 md:w-1/3 px-4 no-underline mb-8"
              key={i}
            >
              {featured_image && (
                <Img
                  fluid={{ ...featured_image.fluid, aspectRatio: 1 }}
                  className="w-full"
                />
              )}
              <div className="pt-4">
                {publish_date && (
                  <p className="text-sm">{formatDate(publish_date)}</p>
                )}
                {title && <h4>{title.text}</h4>}
              </div>
            </Link>
          )
        })}
      </div>
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  {
    allPrismicBlog(sort: { fields: data___publish_date, order: DESC }) {
      edges {
        node {
          data {
            title {
              text
            }
            publish_date
            featured_image {
              fluid(maxWidth: 800) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
          uid
        }
      }
    }
  }
`
